import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IProficiencyLevel } from '../competencies/competencies.models';
import { TenantsApiService } from './tenants-api.service';
import { TenantSettings } from './tenants.models';
import { RouteConstants } from '@app/shared';

@Injectable({
	providedIn: 'root'
})
export class TenantSettingsService {

	private _tenantSettings = new BehaviorSubject<TenantSettings>(null);
	public tenantSettings$ = this._tenantSettings.asObservable();
	public get tenantSettings (): TenantSettings {
		return this._tenantSettings.value;
	}
	public set tenantSettings (v: TenantSettings) {
		this._tenantSettings.next(v);
	}
	public isUserRatingEnabled$ = this.tenantSettings$.pipe(
		map(settings => {
			return settings?.userRatingSetting ? settings.userRatingSetting.isEnabled : false;
		})
	);

	private _proficiencyLevels = new BehaviorSubject<IProficiencyLevel[]>(null);
	public proficiencyLevels$ = this._proficiencyLevels.asObservable();
	public proficiencyLevelsCount$ = this.proficiencyLevels$.pipe(
		map(levels => levels?.length ?? 0)
	);
	public get proficiencyLevels (): IProficiencyLevel[] {
		return this._proficiencyLevels.value;
	}
	public set proficiencyLevels (value: IProficiencyLevel[]) {
		this._proficiencyLevels.next(value);
	}

	constructor (private readonly tenantApiService: TenantsApiService, private router: Router) { }

	loadTenantSettings (): Observable<TenantSettings> {
		return this.tenantApiService.getTenantSettings().pipe(
			map(data => {
				this.tenantSettings = data.records;
				return this.tenantSettings;
			}),
			catchError(data => {
				if (data.status === 404) {
					this.router.navigate([RouteConstants.Error404]);
				}
				return throwError(() => data);
			})
		);
	}

	loadProficiencyLevels = (): Observable<IProficiencyLevel[]> => {
		return this.tenantApiService.getProficiencyLevels().pipe(
			map(data => data.records),
			tap(data => this.proficiencyLevels = data),
			catchError(data => {
				if (data.status === 404) {
					this.router.navigate([RouteConstants.Error404]);
				}
				return throwError(() => data);
			})
		);
	};
}