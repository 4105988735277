import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantSettingsService } from '../tenant-settings.service';

@Injectable({
	providedIn: 'root'
})
export class MyProficiencyLevelsLoadedGuard  {

	constructor(private readonly tenantService: TenantSettingsService) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.tenantService.loadProficiencyLevels().pipe(
			map(data => { return !!data; })
		);
	}
}
